

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
.box.spinning {
    position: relative;
    @extend .fa-spinner;
    &:before {
        display: inline-block; margin-right: 10px;
        font: normal normal normal 14px/1 FontAwesome;
        animation-name: spin; animation-duration: 500ms; animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}



input, textarea {
    border: 1px solid $black;
    &.has-error {
        border: 1px solid $red;
    }
}

.form-sep {
    border-top: 1px solid #eeeeee; margin-bottom: 20px;
    margin-top: 25px; /* if after .form-group, collapses and adds 5px */
    &-green { border-top-color: $green; }
    &-thick { border-top-width: 2px; }
}

.form-group { margin-bottom: 20px; }

.form-alert {
    font-size: 11px; background-color: rgba($red, 0.1); color: $red;
    border: 1px solid rgba($red, 0.2); border-radius: 2px; padding: 10px 15px;
    margin-bottom: 2px;
    .fa { margin-right: 5px; font-size: 12px; }
}

.form-footer {
    padding-top: 15px;
}

label.control-label {
    color: $gray; font-size: 12px; margin-bottom: 7px;
    &, * { &::selection { background: none; color: inherit; } }
    &.has-error {
        color: $red;
    }
    &.required {
        &:after {
            content: '*'; display: inline-block; color: $red;
            margin-left: 5px;
        }
    }
}

input.form-control, textarea.form-control {
    border: 0; border-bottom: 1px solid #cccccc; border-radius: 0;
    color: $black; font-size: 12px; box-shadow: none;
    background-color: #f7f7f7; height: 40px; padding: 9px 15px 9px 15px;
    @include placeholder {
        color: $gray;
    }
    &:focus {
        border: 0; border-bottom: 1px solid $green; box-shadow: none;
        background-color: #ebf6f4; transition: all 0.2s ease-in;
    }
    &.has-error {
        border-bottom-color: $red; background-color: #fde7e7; color: $red;
        @include placeholder { color: $red; }
    }
}

textarea { resize: none; }


.custom-radio, .custom-checkbox {
    position: relative; display: inline-flex; margin-right: 7px; margin-bottom: 10px;
    input {
        opacity: 0; -webkit-appearance: none; @include abs(0,0); margin: 0px; padding: 0px;
        @include size(100%); cursor: pointer;
    }
    label {
        @extend .caps; @extend .box, .box-ml, .box-outline-black;
        display: inline-flex; align-items: flex-start; vertical-align: middle; font-size: 11px;
        &:before {
            content: ''; display: inline-block; position: relative; vertical-align: middle;
            @include size(12px); margin-left: 8px; border: 1px solid $black; border-radius: 50%;
            order: 2; pointer-events: none;
        }
        &:after {
            content: ''; /* @extend .i-checkmark-tall-white;  */margin-left: 8px;
            pointer-events: none;
            display: none;
        }
        * { vertical-align: middle; }
    }
    input:checked + label {
        @extend .box-black; color: $black;
        &:before { display: none; }
        &:after { display: inline-block; }
    }
    &.w-100 {
        label { @include flex-apart(); width: 100%; }
    }
}

.simple-checkbox, .simple-radio {
    display: inline-block; position: relative;
    label {
        display: inline-block; font-size: 12px; color: $gray;
        cursor: pointer;
        &::selection,span::selection { background: none; color: inherit; }
        &:before {
            content: ''; display: inline-block; margin-right: 5px;
            /* @extend .i-checkbox-gray; */ transform: scale(calc(2/3));
            vertical-align: middle;
        }
        /*&:before {
            content: ''; margin-right: 10px; display: inline-block;
            border: 1px solid #f5f5f5; @include size(24px); padding: 2px;
            border-radius: 50%; vertical-align: middle;
        }
        &:after {
            content: ''; display: block; @include abs(4px, 7px);
            @extend .i-checkmark-fat-gray;
            transform: scale(calc(12/16));
        }*/
    }
    input { display: none; }
    input:checked + label {
        color: $black;
        &:before {
            /* content: ''; display: inline-block; @extend .i-checkbox-green; */
        }
        /*&:before {
            background-color: $green; border-color: $green;
        }
        &:after { @extend .i-checkmark-fat-white; }*/
    }
    &-square {
        label {
            /* &:before { @extend .i-checkbox-square-gray; margin-left: -6px; } */
        }
        input:checked + label {
            /* &:before { @extend .i-checkbox-square-green; } */
        }
    }
}

.input-group.date {
    cursor: pointer;
    input.form-control {
        @extend .caps; border-top-left-radius: 7px; border-bottom-left-radius: 7px;
        border: 1px solid $black; border-right: 0; background-color: $white;
        color: $black; height: 36px; cursor: pointer;
    }
    .input-group-addon {
        border: 1px solid $black; border-left: 0; background-color: $white;
        border-top-right-radius: 7px; border-bottom-right-radius: 7px;
    }
}
@each $generic-color-name, $generic-color in $brand-colors {
    .input-group.input-group-#{$generic-color-name} {
    input.form-control, .input-group-addon {
        background-color: $generic-color; border-color: $generic-color;
        color: $black;
    }
    /* for IE - creates a gap between them without this */
    input.form-control { border-right: 1px solid $generic-color; }
    .input-group-addon { border-left: 1px solid $generic-color; }
}
}



.datepicker {
    border: 0; box-shadow: 0px 1px 12px 0px rgba(29, 69, 183, 0.12);
    border-radius: 6px; overflow: hidden;
    table {
        tr {
            td {
                font-size: 13px;
                &.old, &.new { color: rgba($black, 0.5);  }
                &:hover { border-radius: 50%; }
                &.today {
                    background-color: rgba($black, 0.7); color: $white;
                    border-radius: 50%;
                }
                &.active.active { border-radius: 50%; background-color: $black; }
            }
        }
    }
    &-days {
        table {
            thead {
                th { border-radius: 0; }
                tr:nth-child(2){ /* month and arrows */
                    background-color: $black;
                    th, td {
                        color: $white;
                        &:hover { background-color: transparent; }
                    }
                }
                tr:nth-child(3){ /* day of week */
                    background-color: $black;
                    th, td { color: $white; font-size: 13px; }
                }
            }
        }
    }
}


.custom-range-slider {
    padding-top: 8px;
    .noUi-target { border: 0; box-shadow: none; background-color: $black; }
    .noUi-horizontal {
        height: 6px; cursor: pointer;
        .noUi-handle {
            box-shadow: 1px 1px 5px 0px rgba(7, 38, 98, 0.15); border-radius: 50%;
            border: 0; @include size(22px); top: -8px; cursor: pointer;
            &:before { background: $black; left: 9px; top: 8px; height: 6px; }
            &:after { background: $black; left: 12px; top: 8px; height: 6px; }
        }
        .noUi-tooltip {
            bottom: auto; top: 120%; padding: 1px 3px; font-size: 11px; color: $black;
            border: 1px solid $black;
        }
    }
    .noUi-base { }
    .noUi-connect { background: $black; }
}

.form-captcha {
    border: 1px solid #cccccc; height: 40px;
}



.contact-text-right {
    margin-top: 50px; text-align: right;
    &, * { @extend .caps; color: $darker-gray; font-size: $f-xl; }
}

.contact-heading {
    margin-top: 80px; font-size: 40px;
    @media(max-width: ($screen-sm - 1)){
        margin-top: 50px;
    }
}

.contact-form {
    background-color: $bluish-gray; padding-top: 60px; padding-bottom: 30px;
    &-footer {
        text-align: right; margin-top: 30px;
    }
    form {
        display: block; width: 45%; margin: 0px auto;
    }
    .form-control {
        background-color: transparent; @extend .caps; font-size: $f-lg;
        padding-left: 0px; color: $gray;
    }
}
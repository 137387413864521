
@import "pages/homepage";
@import "pages/contact";
@import "pages/current-exhibitions";

.exhibitions-content {
    padding-left: 80px; padding-top: 70px;
}

.inner-content {
    padding-top: 18px;
}
.inner-slider {
    >.inner-slide {
        display: none;
        /* still take space to avoid content after slider jumping on load */
        &:first-child { display: block; visibility: hidden; }
    }
    .slick-arrow {
        @extend .i-arrow-right; transform: none; /*reset default translate transform*/
        &:before { display: none; }
        &.slick-prev { right: calc(100% + 32px); left: auto; transform: rotate(180deg); }
        &.slick-next { left: calc(100% + 32px); right: auto; }
    }
    /*must be at the bottom edge of slide img, so use hardcoded values in media queries (default is for lg) */
    @media (max-width: ($screen-sm-min - 1)){
        margin: 0 20px;
        .slick-arrow {
            top: 144px;
            &.slick-prev { right: calc(100% + 10px); left: auto; transform: rotate(180deg) scale(0.7); }
            &.slick-next { left: calc(100% + 10px); right: auto; transform: scale(0.7); }
        }
    }
    @media (max-width: 375px){
        .slick-arrow { top: 181px; }
    }
    @media (max-width: 320px){
        .slick-arrow { top: 144px; }
    }
    @media (min-width: $screen-sm-min){
        margin: 0px 55px;
        .slick-arrow { top: 386px; }
    }
    @media (min-width: $screen-md-min){
        .slick-arrow { top: 386px; }
    }
    @media (min-width: $screen-lg-min){
        margin: 0px 100px;
        .slick-arrow { top: 426px; }
    }
    /*.slick-slide.activating {
        .inner-slide-desc { opacity: 0; transition: none; }
    }*/
    &.sliding {
        .inner-slide-desc { opacity: 0; transition: none; }
    }
}
.inner-slide {
    &-img { @include img(1.5); margin-bottom: 22px; }
    &-desc {
        font-size: 10px; color: $dark-gray; transition: opacity 0.2s ease-in; 
    }
}


@media all and (-ms-high-contrast:none)
{
    /* fix mistletoe */
    @media (min-width: 1200px){
        .fluid-2col-title,
        .h,
        .inner-feat-title {
            &:before { bottom: calc(100% - 75px); }
        }
    }
    /* IE10 & IE 11*/
    .ol-2col-numbers ol li {
        flex: 45%;
    }
    .item {
        &.item-news {
            .item-details {
                /* flex: 53% resulted in box-sizing: content-box behavior - 
                   - padding was applied outside of that 53% */
                max-width: 53%;
            }
        }
    }
    /* end IE10 & IE 11 */
    *::-ms-backdrop, body {
        /* IE11 only */
        /* end IE11 only */
    }
}

//spritesmith cssOpts parameter doesnt work
@include sprites-custom-prefix($spritesheet-sprites);
@include retina-sprites-custom-prefix($retina-groups);

#__bs_notify__ { top: auto !important; bottom: 0 !important; }

* { outline: none !important; }

html {
    height: 100%;
}
body {
    min-height: 100%; display: flex; flex-direction: column;
    &.homepage { }
}
.global-wrapper {
    flex: 1 0 0;
}


.header {
    padding-top: 45px; margin-bottom: 20px;
    &__content {
        display: flex; flex-direction: row; justify-content: flex-start;
        align-items: flex-end;
    }
    &__left {
        padding-left: 0px;
    }
    &__right {
        border-bottom: 1px solid $darker-gray; display: flex; flex-direction: row;
        align-items: flex-end; padding-bottom: 15px;
        padding-left: 0px; padding-right: 0px;
    }
    &__social {
        i { margin-left: 5px; }
        &:hover { color: $white; }
    }
    &__logo {
        transform: scale(0.9); transform-origin: left top;
    }
    &__lang-link {
        @extend .caps; margin-left: auto; font-size: 25px;
    }
    &.affix {
        /* & + .site-content { } */
    }
}

.main-menu-wrapper {
    flex: 1; display: flex; flex-direction: column; align-items: stretch;
}
.navbar {
    width: 100%; margin-bottom: 0px; border: 0;
    display: flex; align-items: stretch;
}
.navbar-offcanvas {
    width: 100%; display: flex; flex-direction: column; align-items: stretch;
    &-content { width: 100%; display: flex; align-items: stretch; }
}
.navbar-nav {
    width: 100%; display: flex; flex-direction: column;
    justify-content: flex-start; align-items: flex-start;
    padding-left: 110px;        /*125 if the logo isn't scaled*/
    li.disabled {
        pointer-events: none;
        a { color: rgba($dark-gray, 0.35); }
    }
    >li {
        &+li { margin-top: 8px; }
        >a {
            font-size: 25px; color: $dark-gray;
        }
        &:hover, &:focus {
            >a {
                color: rgba($dark-gray, 0.6);
            }
        }
        &.active {
        }
        >ul {
            margin: 8px 0 0 0; padding: 0px;
            display: none;
            li {
                list-style-type: none; position: relative; padding-left: 22px;
                >a {
                    font-size: 22px; color: $dark-gray;
                }
                &:hover {
                    >a {
                        color: rgba($dark-gray, 0.6);
                    }
                }
                &.active {
                    &:before {
                        content: ''; display: block; @include abs(0, 50%);
                        width: 18px; border-top: 1px solid $dark-purple;
                    }
                    >a {
                        color: rgba($dark-gray, 0.6); white-space: nowrap;
                    }
                }
            }
        }
        &.expanded {
            >ul { display: block; }
        }
    }
    .navbar-item-icon-wrapper { @include inline-flex-center(); width: 36px; }
}

.site-content {
    >.container {
        >.row {
            >* {
                /* &:first-child { padding-left: 0px; } */
                /* &:last-child { padding-left: 0px; padding-right: 0px; } */
            }
        }
    }
}
.sidebar-col { padding-left: 0px; }
.content-col { padding-left: 0px; padding-right: 0px; }

.footer {
    margin-top: 120px; padding-bottom: 100px;
    &__h {
        &, * { @extend .caps; }
    }
    &__row {
        >* {
            padding-left: 0px; padding-right: 0px;
        }
    }
    &__copyright {
        font-size: 12px; color: $darker-gray;
    }
    &__created-by {
    }
}

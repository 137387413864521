
@for $i from 1 through 5 {
    .col-xs-#{$i}_5,
    .col-sm-#{$i}_5,
    .col-md-#{$i}_5,
    .col-lg-#{$i}_5 {
	position: relative;
	min-height: 1px;
	padding-right: 10px;
	padding-left: 10px;
    }

    .col-xs-#{$i}_5 {
	width: calc(20% * #{$i});
	float: left;
    }
    @media (min-width: 768px) {
	.col-sm-#{$i}_5 {
            width: calc(20% * #{$i});
            float: left;
	}
    }
    @media (min-width: 992px) {
	.col-md-#{$i}_5 {
            width: calc(20% * #{$i});
            float: left;
	}
    }
    @media (min-width: 1200px) {
	.col-lg-#{$i}_5 {
            width: calc(20% * #{$i});
            float: left;
	}
    }
}

.row {
    @media (min-width: $screen-lg){
        &.gutter-80 {
            margin-left: -40px; margin-right: -40px;
            >* {
                padding-left: 40px; padding-right: 40px;
            }
        }
    }
}

.clearfix { @include clearfix(); }

.w-50 { width: 50%; }
.w-100 { width: 100%; }
.w-auto { width: auto; }

.h-100 { height: 100%; }

.ws-now { white-space: nowrap; }

.w-b-b-w { word-break: break-word; }

.cu-d { cursor: default; }
.cu-p { cursor: pointer; }

.b-r-50 { border-radius: 50%; }

.tr-2_3 { transform: scale(calc(2 / 3)); }

.flex-apart { @include flex-apart(); }
.flex-apart-flex-start { @include flex-apart(flex-start); }
.flex-apart-flex-end { @include flex-apart(flex-end); }
.flex-apart-center { @include flex-apart(center); }
.flex-apart-stretch { @include flex-apart(stretch); }
.flex-center { @include flex-center(); }
.inline-flex-center { @include inline-flex-center(); }


/* works on any direct descendant child elements */
.child-hover-any { @include child-hover('*'); }
/* works on direct descendant .item elements */
.child-hover-item { @include child-hover('.item'); }
/* works on direct descendant bootstrap columns (.col-*) elements */
.child-hover-col { @include child-hover('[class*="col-"]'); }
/* works even on .item elements deep below the tree, not just direct descendants */
.offspring-hover-item { @include offspring-hover('.item'); }


$op-gen-list: 0 1 2 3 4 5 6 7 8 9 10 11 12 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
@each $op-gen in $op-gen-list {
    .op-#{$op-gen} { opacity: #{$op-gen/100}; }
}

.db, .d-b { display: block; }
.dib, .d-i-b { display: inline-block; }

.vat, .v-a-t { vertical-align: top; }
.vam, .v-a-m { vertical-align: middle; }
.vatt, .v-a-t-t { vertical-align: text-top; }
.vatb, .v-a-t-b { vertical-align: text-bottom; }

.wsn, .w-s-n { white-space: nowrap; }

@media (max-width: 767px){
    .text-xs-left { text-align: left; }
    .text-xs-center { text-align: center; }
    .text-xs-right { text-align: right; }
}
@media (min-width: 768px){
    .text-sm-left { text-align: left; }
    .text-sm-center { text-align: center; }
    .text-sm-right { text-align: right; }
}
@media (min-width: 992px){
    .text-md-left { text-align: left; }
    .text-md-center { text-align: center; }
    .text-md-right { text-align: right; }
}
@media (min-width: 1200px){
    .text-lg-left { text-align: left; }
    .text-lg-center { text-align: center; }
    .text-lg-right { text-align: right; }
}
/* place here to allow overriding components (e.g. .box) */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

//.sm .md .lg .xl etc
@each $font-size-name, $font-size in $font-sizes {
    .#{$font-size-name} { font-size: $font-size; }
}

$generic-font-size-min: 8;
$generic-font-size-max: 48;
@for $generic-font-size from $generic-font-size-min through $generic-font-size-max {
    .f-#{$generic-font-size} { font-size: #{$generic-font-size}px; }
}
@media (max-width: 767px){
    @for $generic-font-size from $generic-font-size-min through $generic-font-size-max {
        .f-xs-#{$generic-font-size} { font-size: #{$generic-font-size}px; }
    }
}
@media (max-width: 767px) and (orientation: portrait){
    @for $generic-font-size from $generic-font-size-min through $generic-font-size-max {
        .f-xs-pt-#{$generic-font-size} { font-size: #{$generic-font-size}px; }
    }
}
@media (max-width: 767px) and (orientation: landscape){
    @for $generic-font-size from $generic-font-size-min through $generic-font-size-max {
        .f-xs-ls-#{$generic-font-size} { font-size: #{$generic-font-size}px; }
    }
}
@media (min-width: 768px) and (max-width: 991px){
    @for $generic-font-size from $generic-font-size-min through $generic-font-size-max {
        .f-sm-#{$generic-font-size} { font-size: #{$generic-font-size}px; }
    }
}
@media (min-width: 992px) and (max-width: 1199px){
    @for $generic-font-size from $generic-font-size-min through $generic-font-size-max {
        .f-md-#{$generic-font-size} { font-size: #{$generic-font-size}px; }
    }
}
@media (min-width: 1200px){
    @for $generic-font-size from $generic-font-size-min through $generic-font-size-max {
        .f-lg-#{$generic-font-size} { font-size: #{$generic-font-size}px; }
    }
}

@mixin xs() { font-size: $f-xs; }
@mixin sm() { font-size: $f-sm; }
@mixin ms() { font-size: $f-ms; } /* medium small (14px) */
@mixin md() { font-size: $f-md; }
@mixin ml() { font-size: $f-ml; }
@mixin xl() { font-size: $f-xl; }
@mixin xxl() { font-size: $f-xxl; }

@mixin non-bold() { font-weight: normal; }
.non-bold { @include non-bold(); }
@mixin bold() { font-weight: bold; }
.bold { @include bold(); }
@mixin underline() { text-decoration: underline; }
.underline { @include underline(); }
@mixin italic() { font-weight: italic; }
.italic { @include italic(); }

.lh-100 { line-height: 100%; }
.lh-150 { line-height: 150%; }

/* .white { color: #fff; } */
/* .black { color: #000; } */
/* @mixin green() { color: $green; } */
/* @mixin gray() { color: $gray; } */
/* .green { @include green(); } */
/* .gray { @include gray(); } */

@each $generic-color-name, $generic-color in $brand-colors {
    .#{$generic-color-name} {
    color: $generic-color;
}
}

@each $generic-color-name, $generic-color in $brand-colors {
    .bg-#{$generic-color-name} {
    background-color: $generic-color;
}
}

$margin-gen-list: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 120 150 200;
@each $margin-gen in $margin-gen-list {
    .m-#{$margin-gen} { margin: #{$margin-gen}px; }
    .m-t-#{$margin-gen} { margin-top: #{$margin-gen}px; }
    .m-b-#{$margin-gen} { margin-bottom: #{$margin-gen}px; }
    .m-r-#{$margin-gen} { margin-right: #{$margin-gen}px; }
    .m-l-#{$margin-gen} { margin-left: #{$margin-gen}px; }
    .p-#{$margin-gen} { padding: #{$margin-gen}px; }
    .p-t-#{$margin-gen} { padding-top: #{$margin-gen}px; }
    .p-b-#{$margin-gen} { padding-bottom: #{$margin-gen}px; }
    .p-r-#{$margin-gen} { padding-right: #{$margin-gen}px; }
    .p-l-#{$margin-gen} { padding-left: #{$margin-gen}px; }
}
/* separate responsive classes to avoid css priority issues */
@each $margin-gen in $margin-gen-list {
    @media (max-width: 767px){
        .m-xs-#{$margin-gen} { margin: #{$margin-gen}px; }
        .m-t-xs-#{$margin-gen} { margin-top: #{$margin-gen}px; }
        .m-b-xs-#{$margin-gen} { margin-bottom: #{$margin-gen}px; }
        .m-r-xs-#{$margin-gen} { margin-right: #{$margin-gen}px; }
        .m-l-xs-#{$margin-gen} { margin-left: #{$margin-gen}px; }
        .p-xs-#{$margin-gen} { padding: #{$margin-gen}px; }
        .p-t-xs-#{$margin-gen} { padding-top: #{$margin-gen}px; }
        .p-b-xs-#{$margin-gen} { padding-bottom: #{$margin-gen}px; }
        .p-r-xs-#{$margin-gen} { padding-right: #{$margin-gen}px; }
        .p-l-xs-#{$margin-gen} { padding-left: #{$margin-gen}px; }
    }
}
@each $margin-gen in $margin-gen-list {
    @media (min-width: 768px){
        .m-sm-#{$margin-gen} { margin: #{$margin-gen}px; }
        .m-t-sm-#{$margin-gen} { margin-top: #{$margin-gen}px; }
        .m-b-sm-#{$margin-gen} { margin-bottom: #{$margin-gen}px; }
        .m-r-sm-#{$margin-gen} { margin-right: #{$margin-gen}px; }
        .m-l-sm-#{$margin-gen} { margin-left: #{$margin-gen}px; }
        .p-sm-#{$margin-gen} { padding: #{$margin-gen}px; }
        .p-t-sm-#{$margin-gen} { padding-top: #{$margin-gen}px; }
        .p-b-sm-#{$margin-gen} { padding-bottom: #{$margin-gen}px; }
        .p-r-sm-#{$margin-gen} { padding-right: #{$margin-gen}px; }
        .p-l-sm-#{$margin-gen} { padding-left: #{$margin-gen}px; }
    }
}
@each $margin-gen in $margin-gen-list {
    @media (min-width: 992px){
        .m-md-#{$margin-gen} { margin: #{$margin-gen}px; }
        .m-t-md-#{$margin-gen} { margin-top: #{$margin-gen}px; }
        .m-b-md-#{$margin-gen} { margin-bottom: #{$margin-gen}px; }
        .m-r-md-#{$margin-gen} { margin-right: #{$margin-gen}px; }
        .m-l-md-#{$margin-gen} { margin-left: #{$margin-gen}px; }
        .p-md-#{$margin-gen} { padding: #{$margin-gen}px; }
        .p-t-md-#{$margin-gen} { padding-top: #{$margin-gen}px; }
        .p-b-md-#{$margin-gen} { padding-bottom: #{$margin-gen}px; }
        .p-r-md-#{$margin-gen} { padding-right: #{$margin-gen}px; }
        .p-l-md-#{$margin-gen} { padding-left: #{$margin-gen}px; }
    }
}
@each $margin-gen in $margin-gen-list {
    @media (min-width: 1200px){
        .m-lg-#{$margin-gen} { margin: #{$margin-gen}px; }
        .m-t-lg-#{$margin-gen} { margin-top: #{$margin-gen}px; }
        .m-b-lg-#{$margin-gen} { margin-bottom: #{$margin-gen}px; }
        .m-r-lg-#{$margin-gen} { margin-right: #{$margin-gen}px; }
        .m-l-lg-#{$margin-gen} { margin-left: #{$margin-gen}px; }
        .p-lg-#{$margin-gen} { padding: #{$margin-gen}px; }
        .p-t-lg-#{$margin-gen} { padding-top: #{$margin-gen}px; }
        .p-b-lg-#{$margin-gen} { padding-bottom: #{$margin-gen}px; }
        .p-r-lg-#{$margin-gen} { padding-right: #{$margin-gen}px; }
        .p-l-lg-#{$margin-gen} { padding-left: #{$margin-gen}px; }
    }
}

$ge-default: 'BPGPhoneSans';
$ge-caps-primary: 'GLKirovi-Bold';
$ge-caps-secondary: 'GLKirovi-Bold';

$en-default: 'BPGPhoneSans';
$en-caps-primary: 'GLKirovi-Bold';
$en-caps-secondary: 'GLKirovi-Bold';
/* $en-default: $font-family-sans-serif;
   $en-caps-primary: $font-family-sans-serif;
   $en-caps-secondary: $font-family-sans-serif; */

.en {
    *:not(.fa) {
        font-family: $en-default;
    }
    /* capitalize only root level menu items */
    .caps, .navbar-nav > li > a, .navbar-nav > li > ul > li > a {
        font-family: $en-caps-primary; text-transform: uppercase;
    }
    .some-class-with-eng-caps-secondary {
        font-family: $en-caps-secondary; text-transform: uppercase;
    }
    .caps .fa { position: relative; top: -1px; }
}

.ge {
    *:not(.fa) {
        font-family: $ge-default;
    }
    /* capitalize only root level menu items */
    .caps, .navbar-nav > li > a, .navbar-nav > li > ul > li > a {
        font-family: $ge-caps-primary; text-transform: uppercase;
    }
    .some-class-with-geo-caps-secondary {
        font-family: $ge-caps-secondary; text-transform: uppercase;
    }
    @media (min-width: 320px) and (max-width: 767px){
        .credit-card-remove-btn-tooltip {
            font-family: $ge-caps-secondary; text-transform: uppercase;
        }
    }
    .h { @extend .caps; }
    .h-sub {
        font-family: $ge-default;
    }
    .caps .fa { position: relative; top: -2px; }
    /* GL Kirovi doesn't need artificial extra top padding */
    /*
    // .box-md must be first, to avoid overriding others
    .box-md { padding-top: 10px; padding-bottom: 6px; }
    //
    .box-xs { padding-top: 7px; padding-bottom: 3px; }
    .box-sm { padding-top: 8px; padding-bottom: 3px; }
    // larger font-size/em, so smaller padding
    .box-ms { padding-top: 8px; padding-bottom: 3px; }
    .box-ml {
        padding-top: 12px; padding-bottom: 8px;
        .fa { top: -3px; }
    }
    .box-lg {
        padding-top: 16px; padding-bottom: 12px; 
        .fa { top: -3px; }
    }
    .box-xl {
        padding-top: 16px; padding-bottom: 12px; 
        .fa { top: -3px; }
    }
    */
}

/* Base styles (_base) */

a:focus, a:hover { text-decoration: none; }

img { max-width: 100%; }




*[class^="i-"], *[class*=" i-"] { display: inline-block; vertical-align: middle; }

@mixin styled-jsp(){
    .jspTrack {
        border-radius: 4px; background-color: rgba($black, 0.05);
    }
    .jspDrag {
        background-color: rgba($black, 0.5); border-radius: 8px;
    }
    .jspHorizontalBar {
        background: $white; height: 8px; margin-bottom: 2px;
        .jspDrag {
            /* two-line handle */
            /*&:before {
                content: ''; display: block; width: 4px; height: 4px;
                 @include abs(calc(50% - 2px), 6px);
                border-left: 1px solid $white;
                border-right: 1px solid $white;
            }*/
        }
    }
    .jspVerticalBar {
        background: $white; width: 8px; margin-right: 2px;
        .jspDrag {
            /* two-line handle */
            /*&:before {
                content: ''; display: block; width: 4px; height: 4px;
                @include abs(6px, calc(50% - 2px));
                border-top: 1px solid $white;
                border-bottom: 1px solid $white;
            }*/
        }
    }
    .jspCorner { background: transparent; }
}



a {
    color: $blue;
    &:hover {
        &, .fa { text-decoration: none; color: $black; }
    }
}

h4 {
    @extend .caps; font-size: 15px; color: $black; font-weight: bold;
}
/* h6 used as subtitle */
h6 {
    color: $gray; font-size: 12px; margin-bottom: 15px;
}

.fa { vertical-align: middle; }
.box .fa { vertical-align: top; }

a, button, input, .box, li, .select-wrapper,
.item, .item-title, .item-img-more-wrapper, .hover-icon-wrapper i, .dropdown-menu a, .d.uber-menu, .uber-menu ul.nav >li >ul {
    &, .fa { transition: all 0.2s ease-in; }
}
/* separate because :before/:after selectors cannot have child selectors */
.slick-arrow:before,
.slick-arrow:after {
    transition: all 0.2s ease-in;
}
.navbar-subitems-expand:before,
.uber-menu-subitems-expand:before {
    transition: transform 0.2s ease-in;
}

.w-50 { width: 50%; }
.w-100 { width: 100%; }
.w-auto { width: auto; }

@mixin sm() { font-size: $f-sm; }
.sm { @include sm(); }
@mixin md() { font-size: $f-md; }
.md { @include md(); }
@mixin ml() { font-size: $f-ml; }
.ml { @include ml(); }
@mixin lg() { font-size: $f-lg; }
.lg { @include lg(); }

@mixin unbold() { font-weight: normal; }
.unbold { @include unbold(); }
@mixin bold() { font-weight: bold; }
.bold { @include bold(); }
@mixin italic() { font-weight: italic; }
.italic { @include italic(); }


.gel, .GEL {
    font-family: '_bpg_gelregular' !important; display: inline !important;
    &:before { content: 'A'; display: inline; font-size: 90%; line-height: 1; }
    &-end {
        font-family: '_bpg_gelregular' !important; display: inline !important;
        &:after {
            content: 'A'; display: inline; font-size: 90%; line-height: 1;
            margin-left: 3px;
        }
    }
}
.usd, USD {
    display: inline !important;
    &:before { content: '$'; display: inline; font-size: 95%; line-height: 1; }
    &-end {
        &:after {
            content: '$'; display: inline; font-size: 95%; line-height: 1;
            margin-left: 3px;
        }
    }
}
.eur, EUR {
    display: inline !important;
    &:before { content: '\20AC'; display: inline; font-size: 95%; line-height: 1; }
    &-end {
        &:after {
            content: '\20AC'; display: inline; font-size: 95%; line-height: 1;
            margin-left: 3px;
        }
    }
}

.nav>li>a {
    padding: 0;
    &:hover, &:focus, &:active {
                          background-color: inherit;
                      }
}

/* default style and lang menu */
.dropdown-menu {
    min-width: auto; border: 1px solid #eee; box-shadow: none;
    border-radius: 0; padding: 0;
    >li {
        >a {
            padding: 0;
        }
    }
}

.header__first-btn {
    .dropdown {
        &-menu {
            min-width: auto; border: 0; border-radius: 0; padding: 8px 0; width: 220px;
            box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3); background-color: #191919;
            margin: 0px;
            >li {
                >a {
                    display: block; padding: 8px 12px; font-size: 14px; color: $white;
                    line-height: 16px; text-align: left;
                    &:before {
                        font: normal normal normal 30px/1 FontAwesome; color: $green;
                        content: ''; margin-right: 8px; /* fa-angle-right */
                        display: inline-block; vertical-align: text-top;
                        font-size: inherit; color: inherit;
                    }
                    &:hover,
                    &.active {
                        color: $blue; background-color: #252525;
                        &:before { font-size: inherit; color: inherit; }
                    }
                }
            }
        }
    }
}

.select-wrapper {
    display: inline-block; position: relative; vertical-align: middle;
    background-color: #f7f7f7; text-align: left; padding: 0px;
    border: 0; border-bottom: 1px solid #cccccc; width: 100%;
    color: $gray; font-size: 11px; height: 40px;
    &:hover { background-color: #ebf6f4; }
    &.value-selected { color: $black; }
    &.has-error {
        border-color: $red; background-color: #fde7e7;
        color: $red;
        .select-trigger:after { color: $red; }
    }
    .select-trigger {
        @include flex-apart(center); padding: 5px 14px 4px 14px;
        font-size: inherit; line-height: inherit; color: inherit;
        &:after {
            /* fa angle down */
            font: normal normal normal 30px/1 FontAwesome; color: $green;
            content: ''; margin-left: 10px;
            transform: rotate(0deg); transition: transform 0.2s ease-in;
        }
        &, span {
            overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
        }
        span { width: calc(100% - 22px); }
	.js-object-filter {
            white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
        }

    }
    .select-results {
        width: 100%; position: absolute; z-index: 40; left: 0; top: calc(100% + 10px);
        /* background-color: $light-blue; */ background-color: $white;
        border-radius: 4px;
        border: 0; border-top: 0; font-size: inherit; line-height: inherit;
        color: inherit;
        display: none;
        /* max-height: 200px; */
        height: 150px; overflow-y: auto; overflow-x: hidden;
        /* : box-shadow: 0px 10px */
        box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.15);
        a {
            display: block; text-align: left; padding: 11px 15px;
            text-decoration: none; color: inherit; font-size: inherit;
            color: $black; line-height: 150%;
            &, span { @extend .caps; }
            & + a { /* border-top: 1px solid rgba($light-blue, 0.2); */ }
            &:hover, &.active {
                background-color: $black;
                /* color: $light-blue; background-color: #f2f2f2; */
            }
        }
    }
    &.open {
        .select-trigger {
            &:after {
                transform: rotate(-180deg);
            }
        }

	.select-results {
	    display: block;
	}
    }
    &.select-wrapper-multiple {
        .select-results {
            a {
                &:before {
                    content: ''; @include inline-flex-center(); margin-right: 10px;
                    @include size(14px); border: 1px solid $black; border-radius: 2px;
                    vertical-align: top;
                }
                &.active {
                    &:before {
                        content: '✔'; color: $white; background-color: $black;
                        font-size: 8px; padding-top: 1px;
                    }
                }
            }
        }
    }
    /*  */
    &.select-wrapper-white {
        background-color: $white; border-color: $black;
        .select-trigger {
            color: $black;
            /* &:after { @extend .i-dropdown-gray; } */
        }
        .select-results {
            background-color: $white;
            a {
                color: $black;
                & + a { /* border-top-color: #f2f2f2; */ }
            }
        }
    }
    @include styled-jsp();
}

.h {
    font-size: 32px; color: $blue; line-height: 1.1; margin-bottom: 40px;
    font-weight: bold; position: relative;
    &-center {
        text-align: center;
    }
    &-sub { color: $gray; font-size: 12px; line-height: 1.5; }
}
.h {
    /*
       position: relative; padding-bottom: 0.5em; margin-bottom: 8px; font-weight: bold;
     */
}
.h-in-right {
    /* right side along h's title */
    display: block; float: right; font-weight: normal; /* reset .h's bold */
}
.h-sm {
    font-size: $f-sm; padding-bottom: 1.3em;
}
.h-md {
    font-size: $f-md; padding-bottom: 1.3rem;
}
.h-ml {
    font-size: $f-ml; padding-bottom: 1.3rem;
}
.h-lg {
    font-size: $f-lg;
}
.h-xl {
    font-size: $f-xl;
}
.h-green {
    color: $green;
}
.h-black {
    color: $black;
}
.h-white {
    color: #fff;
}
.h-gray {
    color: $gray;
}


.sep { margin-top: 30px; margin-bottom: 30px; border-top: 1px solid #e5e5e5; }
.sep-0 { margin-top: 0px;  margin-bottom: 0px;}
.sep-xs { margin-top: 10px; margin-bottom: 10px; }
.sep-sm { margin-top: 15px; margin-bottom: 15px; }
.sep-lg { margin-top: 30px; margin-bottom: 30px; }
.sep-xl { margin-top: 60px; margin-bottom: 60px; }


.box {
    @extend .box-md;
    line-height: 1em;
    border-radius: 0px; display: inline-block; width: auto; text-align: center;
    margin: 0; padding: 0 0.3em; border-width: 1px; border-style: solid;
    font-weight: bold;
    &:not(.non-caps){
        @extend .caps; text-transform: uppercase;
    }
    &.w-100 { width: 100%; }
    /* &.non-caps { text-transform: none; } */
    &-f-light {
        font-family: 'OpenSans-Light';
    }
    &:hover { cursor: pointer; }
}
/* .box-md should be first because .box extends it and should be overwritten by other sizes
 * by CSS order */
/* gl kirovi font doesn't need 7 vs 5 extra top padding */
.box-md { font-size: 13px; padding: 5px 0.3em; }
/* not using &-round or &-wide etc to allow @extending */
.box-md.box-round { border-radius: 1em; }
.box-md.box-wide { padding-left: 15px; padding-right: 15px; }
.box-md.box-wider { padding-left: 30px; padding-right: 30px; }
.box-xs {
}
.box-sm { }
.box-sm.box-wide { padding-left: 20px; padding-right: 20px; }
.box-sm.box-wider { padding-left: 25px; padding-right: 25px; }
.box-ml {
    /* decreased vert. padding by 1px each to match .inner-filter controls */
    /* if it messes up somewhere else, then revert here and decrease .boxes in .inner-filter */
    /* padding: 11px 16px 9px 16px; */ padding: 10px 16px 8px 16px; line-height: 16px;
}
.box-ml.box-round { border-radius: 21px; }
.box-lg { font-size: $f-lg; }
.box-lg.box-round { border-radius: 23px; }
.box-lg.box-wide { padding-left: 25px; padding-right: 25px; }
.box-lg.box-wider { padding-left: 45px; padding-right: 45px; }
.box-xl { font-size: $f-lg; }
.box-xl.box-round { border-radius: 8px; }
.box-xl.box-wide { padding: 10px 20px; }
.box-xl.box-wider { padding-left: 45px; padding-right: 45px; }

.box-outline {
    border-width: 1px; border-style: solid; border-color: transparent;
    background-color: transparent;
}
.box-transparent {
    background-color: transparent; border-color: transparent; color: $black;
}
@each $generic-color-name, $generic-color in $brand-colors {
    .box-#{$generic-color-name} {
    /* reset bootstrap's ridiculous <a>:focus style */
    &, &:focus {
           background-color: $generic-color; border-color: $generic-color;
           color: $black;
       }
}
}
@each $generic-color-name, $generic-color in $brand-colors {
    .box-outline-#{$generic-color-name} {
    /* reset bootstrap's ridiculous <a>:focus style */
    &, &:focus { color: $generic-color; border-color: $generic-color; }
}
}
@each $generic-color-name, $generic-color in $brand-colors {
    .box-outline-#{$generic-color-name}:not(.box-no-hover){
        &:hover,
    /*&:focus,
        &:active*/ {
        color: $white; background-color: $generic-color;
    }
    }
}


.box-blue {
    &, &:focus { color: $white; }
    .fa { color: $black; }
    &:not(.box-no-hover):hover {
        background-color: $black; border-color: $black; color: $white;
        .fa { color: $blue; }
    }
}
.box-white {
    &, &:focus { color: $black; }
    .fa { color: $blue; }
    &:not(.box-no-hover):hover {
        background-color: $blue; border-color: $blue; color: $white;
        .fa { color: $black; }
    }
}
.box-green {
    &, &:focus { color: $white; }
    .fa { color: $black; }
    &:not(.box-no-hover):hover {
        background-color: $dark-green; border-color: $dark-green; color: $white;
        .fa { color: $black; }
    }
}
.box-gray {
    &, &:focus { color: $white; }
    .fa { color: $white; }
    &:not(.box-no-hover):hover {
        background-color: $dark-gray; border-color: $dark-gray; color: $white;
        .fa { color: $white; }
    }
}
.box-darker-gray {
    &, &:focus { color: $white; }
    .fa { color: $white; }
    &:not(.box-no-hover):hover {
        background-color: $gray; border-color: $gray; color: $white;
        .fa { color: $white; }
    }
}
.box-black {
    &, &:focus { color: $white; }
    .fa { color: $blue; }
    &:not(.box-no-hover):hover {
        background-color: $blue; border-color: $blue; color: $white;
        .fa { color: $black; }
    }
}
.box-outline-blue {
    &, &:focus { color: $black; }
    .fa { color: $blue; }
    &:not(.box-no-hover):hover {
        background-color: $blue; border-color: $blue; color: $white;
        .fa { color: $black; }
    }
}
.box-outline-white {
    &, &:focus { color: $white; }
    .fa { color: $black; }
    &:not(.box-no-hover):hover {
        background-color: $white; border-color: $white; color: $blue;
        .fa { color: $black; }
    }
}

.box-square { border-radius: 0; } /* after .box to override default border style when used */

.box-outline-thick { border-width: 2px; } /* after .box-outline to override default style */

.box.disabled {
    background-color: #f1f5fe; border-color: #f1f5fe; color: #a6abb7;
    pointer-events: none;
}

// box icon and hover styles
a,button,input {
}



.inner-page-content {
}

.shadowed {
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.1);
}

.text-box {
    &-shadowed {
        /* box-shadow: 0px 10.392px 26px 0px rgba(0, 0, 0, 0.05); */
    }
}

.two-state-hover {
    .default-state { }
    .hover-state { display: none; }
    &:hover/*,
    &:focus,
    &:active*/ {
        .default-state { display: none; }
        .hover-state { display: inline-block; }
    }
}

.hover-icon-wrapper {
    display: inline-block; position: relative;
    .hover-state { @include abs(0,0); }
}


.tab-switcher {
    &-links {
        
    }
    &-link {
        display: inline-block; margin-right: 50px; color: $gray;
        &:hover, &.active { color: $black; }
    }
    &-tab {
        
    }
}

/* bootstrap tabs */
.nav-tabs {
    border: 0; background-color: transparent; border-radius: 0; margin: 0px;
    display: flex; justify-content: center; align-items: stretch;
    > li {
        margin: 0px 10px; position: relative; z-index: 1;
        flex: auto 0 0; display: flex; flex-direction: row; align-items: stretch;
        /* flex: 0 doesn't work on safari */
        > a {
            border: 0; background-color: #dddddd; color: $gray;
            margin: 0; font-size: 12px; line-height: 24px; border: 0;
            padding: 12px 60px; text-align: center; border-radius: 2px;
            display: flex; justify-content: center; align-items: center;
            &:hover,
            &:focus {
                &, .fa { background-color: $blue; color: $white; }
            }
        }
        &.active {
            > a {
                &,
                &:focus {
                    background-color: $blue; color: $white; border: 0;
                    pointer-events: none;
                }
            }
        }
    }
    &+.tab-content >.tab-pane {
        /* padding-top: 15px; */
    }
    &.w-auto {
        display: inline-flex;
        >li { white-space: nowrap; }
    }
}
.tab-pane {
    /* background-color: $white; */
}




.uber-menu {
    position: fixed; left: 0px; top: 0px; @include size(100%); z-index: 2000;
    background-color: rgba($black, 0.3); opacity: 0; pointer-events: none;
    a, button {
        font-size: 12px; color: #e0e0e0;
        /* color: $std-gray; */
        /* &:hover { color: $red; } */
        &:hover { color: $white; }
    }
    &-content {
        width: 350px; height: 100%; background-color: rgba($black, 0.82);
        box-shadow: 0px 0px 15px 0px rgba(9, 45, 113, 0.12);
        padding: 25px 30px 20px 30px; display: flex; flex-direction: column;
        position: relative;
    }
    &-header {
        flex: 0; width: 100%; align-self: flex-start;
    }
    &-close {
        display: block; width: 100%; text-align: center;
        border: 0; background-color: transparent;
        /* different style, only x on the right */
        text-align: right; font-size: 0px !important;
        i { transform: scale(1.5); }
    }
    &-sep {
        display: block; width: 100%; height: 0px; font-size: 0px; line-height: 0;
        border-top: 1px solid rgba($white, 0.1);
    }
    nav {
        flex: 1;
        ul.nav {
            height: 100%; display: flex; flex-direction: column;
            /* justify-content: center; */
            justify-content: flex-start; padding-top: 25px;
            >li {/* root lvl */
                display: inline-block; list-style-type: none; line-height: 30px;
                &:hover,
                &.active {
                }
                &.expanded {
                    >a {
                        color: $white;
                        .uber-menu-subitems-expand {
                            &:before { transform: rotate(90deg); }
                        }
                    }
                    >ul {
                        /* display: block; */
                        height: 250px; overflow-y: auto; @include styled-jsp();
                        .jspVerticalBar {
                            background-color: transparent;
                            .jspDrag { background-color: #888; }
                        }
                    }
                }
                >a {
                    display: inline-block; @include  flex-apart(stretch);
                    .uber-menu-subitems-expand {
                        @include inline-flex-center(); position: relative;
                        width: 35px; right: -12px;
                        &:before {
                            content: '';
                            /* @extend .i-arrow-white; */
                            /* @extend .i-arrow-std-gray; */
                            display: inline-block;
                        }
                    }
                    &:hover {
                        .uber-menu-subitems-expand {
                            /* &:before { @extend .i-arrow-red; } */
                        }
                    }
                }
                >ul {
                    margin: 0px; padding: 0;
                    height: 0; overflow: hidden; /* display: none; */
                    li {/* >=2 lvl */
                        display: inline-block; width: 100%; list-style-type: none;
                        line-height: 20px;
                        &:before {
                            content: '-'; display: inline-block; margin-right: 5px;
                            color: #e0e0e0;
                            /* color: $std-gray; */
                        }
                        >a {
                            display: inline-block;
                        }
                        >ul {
                            padding: 5px 12px;
                        }
                    }
                }
            }
        }
    }
    &-footer {
        display: block; width: 100%; flex: 0; align-self: flex-end;
    }
    &-links {
        display: block; width: 100%; margin: 0px; padding: 15px 0;
        li {
            display: block; width: 100%; list-style-type: none; line-height: 30px;
        }
    }
    &-ibank-link {
        display: block; width: 100%; text-align: center; padding: 12px 0 10px 0px;
    }
    &-icon-links {
        display: block; width: 100%; text-align: center; margin: 0px;
        padding: 20px 0 0 0;
        li {
            display: inline-block; margin: 0px 15px; vertical-align: middle;
            list-style-type: none;
        }
    }
}
@media screen and (min-width: $grid-float-breakpoint){
    /* placed this to here to keep relevant code together */
    body.uber-menu-open {
        overflow: hidden;
    }
    .uber-menu.in {
        opacity: 1; pointer-events: all;
    }
}


.dashed-link {
    display: inline-block;
    &:before {
        content: '- '; display: inline-block; margin-right: 5px;
    }
    &-100 { display: block; width: 100%; }
}

/* a[href*='pdf']{ @extend .pdf-link; } */

/*.inner-text  p a[href*='pdf'] {
    width: 150px; margin-left: auto; margin-right: auto; display: block;
    @extend .box-red; padding-left: 10px; padding-right: 10px;
    &:before { display: none; }
}*/

.flag-icon-round {
    @extend .flag-icon-squared; border-radius: 50%;
}
.flag-icon.flag-icon-default {
    @extend .flag-icon-round; font-size: $f-ml; margin-right: 5px; vertical-align: middle;
    display: inline-block;
}



.equal-row {
    display: flex; flex-direction: row; flex-wrap: wrap; align-items: stretch;
}
body.browser-safari {
    /* Safari handles flex-wrap incorrectly */
    .equal-row > * { flex: auto 0 0; margin-right: -1px; }
}


.prefixed-dash {
    &:before {
        content: '-'; display: inline-block; margin-right: 5px;
    }
}

.panel-group {
    margin-bottom: 30px;
    .panel {
        border-radius: 0; border: 0; box-shadow: none;
        &, &-default {
            >.panel-heading {
                background-color: transparent;
                &+.panel-collapse>.panel-body { border: 0px; } }
        }
        &-heading {
            border-radius: 0; padding: 0px;
        }
        &-title {
            margin-bottom: 0px;
            a {
                font-size: $f-sm; line-height: ($f-sm + 20); display: block;
                padding: 10px 12px 10px 25px; word-break: break-word;
            }
        }
        &-body {
            border: 0; font-size: 12px; padding: 20px 25px;
            >:last-child { margin-bottom: 0px; }
        }
    }
    &-static {
        .panel {
            &-heading {  }
            &-title {
                a {
                    background-color: $black; color: $white;
                    border-top-left-radius: 9px; border-top-right-radius: 9px;
                }
            }
            /* &-body:not([class^='p-']):not([class*=' p-']) { */
            &-body {
                padding: 0px;
            }
        }
    }
    &-accordion {
        .panel {
            /* :first, :last and &+.panel style at the end of this block */
            &-heading { }
            &-title {
                a {
                    background-color: $black; color: $white;
                    display: flex; flex-direction: row; justify-content: space-between;
                    &:after {
                        @include size(40px, 30px); min-width: 40px; margin-left: 10px;
                        @include flex-center();
                        font-size: 16px;
                        content: '-'; color: #1f469d; display: inline-block;
                        background-color: $white; border-radius: 7px; text-align: center;
                    }
                    &.collapsed {
                        background-color: #f1f5fe; color: #1f469d;
                        border-bottom: 1px solid $white;
                        &:after { content: '+'; }
                        &:hover {
                            background-color: $black; color: $white;
                        }
                    }
                }
            }
            &-body {
                .inner-text:last-child {
                    p:last-child { margin-bottom: 0px; }
                }
            }
            &:first-child {
                .panel-title {
                    a {
                        border-top-left-radius: 9px; border-top-right-radius: 9px;
                        border-bottom-left-radius: 0; border-bottom-right-radius: 0;
                    }
                }
            }
            &:last-child {
                .panel-title {
                    a {
                        border-top-left-radius: 0; border-top-right-radius: 0;
                        border-bottom-left-radius: 0; border-bottom-right-radius: 0;
                        /* only make last item bottom corners round when it's closed */
                        &.collapsed {
                            border-bottom-left-radius: 9px; border-bottom-right-radius: 9px;
                        }
                    }
                }
            }
            &+.panel { margin-top: 0px; }
        }
    }
}


.fancybox-slide>*:not(.fancybox-loading) {
    width: 45%; min-width: 600px; padding: 0px; border-radius: 7px;
}
.fancybox-loading {
    @include size(20px); border-width: 10px; border-radius: 50%;
}
.fancybox-close-small {
    @extend .i-x-close-fancybox; @include absr(22px,22px); color: transparent;
    background-color: transparent; opacity: 0.3; transform: scale(1.3);
    transition: all 0.2s ease-in;
    &:hover {
        @extend .i-x-close-fancybox; transform: none; color: transparent;
        background-color: transparent; opacity: 0.6; transform: scale(1.3);
    }
}
.fancybox-popup-title {
    background-color: $blue; padding: 16px 20px 14px 20px;
    color: $white; font-size: $f-lg; line-height: 30px;
}
.fancybox-popup-content {
    padding: 30px 75px;
    .form-footer {
        background-color: #f5f5f5; text-align: right; border-top: 1px solid #e9e9e9;
        margin: 40px -75px -30px -75px; padding: 15px 60px;
        .box {
            width: auto;
            .fa { font-size: 17px; vertical-align: text-bottom; }
        }
    }
}

.popups-wrapper { display: none; }
.popup {
    &-desc {
        text-align: center; color: $gray; font-size: 18px;
    }
    &.popup-form {
        
    }
    &.popup-alert {
        .form-footer {
            text-align: center;
            .box { float: none; display: inline-block; }
        }
    }
}

/* fix paragraphs inside table cells */
td p, td a { margin-bottom: 0px; }

.bg-odd { background-color: #f5f5f5; /* rgba($black, 0.04) */ }
.bg-even { background-color: $white; }

@mixin text-wrapper(){
    &, p { color: $dark-gray; font-size: 13px; text-align: left; line-height: 160%; }
    h5 {
        color: $black; font-size: 14px; font-weight: bold; margin-bottom: 25px;
        line-height: 170%;
    }
    p {
        & + p { margin-bottom: 20px; }
    }
    ul {
        margin: 0 0 20px 0; padding: 0px;
        li {
            list-style-type: none;
            &:before {
                content: '- ';
            }
            & + li { margin-top: 0; }
        }
    }
    @media (min-width: $screen-sm-min) and (max-width: ($screen-lg - 1px)){
        &, p { font-size: 16px; }
        h5 { font-size: 18px; }
        ul li { }
    }
    @media (min-width: 320px) and (max-width: ($screen-sm - 1)){
        &, p { font-size: 14px; }
        h5 { font-size: 15px; }
        ul li {
            font-size: 14px;
        }
    }
}

.text-block {
    @include text-wrapper();
}

.block {
    & + .block { margin-top: 50px; }
}

.inner {
    &-wrapper {
    }
    &-map-div-wrapper { }
    &-map-div { }
    &-map { @include size(100%, 350px); }
}

.item {
    /* generic item styles */
    display: block; margin-bottom: 30px; position: relative;
    .item-img {
        @include img(1); display: block; width: 100%;
        margin-bottom: 15px; position: relative;
        &-play {
            @include abs(0); @include size(100%); @include flex-center();
            &-icon {
                display: block; border-radius: 50%; @include size(90px);
                background-color: rgba($white, 0.6); @include flex-center();
                &:before {
                    content: ''; display: block; @extend .i-play; margin-left: 5px;
                }
            }
        }
    }
    .item-details { display: block; width: 100%; position: relative; }
    .item-title {
        font-size: $f-ms; color: $dark-gray; margin-bottom: 5px;
        font-weight: bold; display: block; width: 100%; word-break: break-word;
        &:after { content: ' /'; color: $dark-purple; }
    }
    .item-date {
        /* font-size: 11px; color: #6e7178; margin-bottom: 15px; */
    }
    .item-desc {
        display: block; width: 100%; font-size: 13px; color: $dark-gray;
        margin-bottom: 10px; word-break: break-word;
    }
    &.item-home-event {
        margin-bottom: 0px;
        .item-img { @include img(1.52); margin-bottom: 20px; }
        .item-caption { margin-bottom: 0px; font-size: 10px; }
        .item-details {
            margin-top: 35px;
        }
        .item-logo {
            /* @include img-contain(2); */ background-position: left top;
            background-size: contain; background-repeat: no-repeat;
            /* @include absr(0, 10px); width: 30%; */ width: 100%; height: 50px;
            margin-bottom: 10px;
        }
        .item-title {
            @extend .caps; margin-bottom: 5px; font-size: 35px;
            padding-right: 30%; /* for .item-img/logo */
        }
        .item-subtitle {
            @extend .caps; margin-bottom: 20px; font-size: 25px; color: $dark-gray;
        }
        .item-desc { margin-bottom: 20px; font-size: 13px; color: $dark-gray; }
        .item-date { margin-bottom: 0; font-size: 13px; color: $dark-gray; }
    }
    &.item-program {
        display: flex; flex-direction: row; align-items: stretch;
        margin-bottom: 60px;
        .item-img {
            @include img(1.52); flex: 1; order: 2; margin-bottom: 0px;
        }
        .item-details {
            flex: 1; text-align: right; display: flex; flex-direction: column;
            justify-content: flex-end; align-items: flex-end; padding-right: 30px;
        }
        .item-title {
            @extend .caps; margin-bottom: 0px; font-size: 35px; line-height: 1.3;
        }
        .item-subtitle {
            @extend .caps; margin-bottom: 10px; font-size: 25px; color: $dark-gray;
            line-height: 1.1;
        }
        .item-desc { margin-bottom: 0; font-size: 13px; color: $dark-gray; }
    }
    &.item-press {
        width: 45%; margin: 0 auto 30px auto;
        .item-details {
            text-align: center; padding: 30px 40px 0px 40px;
        }
        .item-title {
            @extend .caps; margin-bottom: 0px; font-size: 35px; line-height: 1.3;
        }
        .item-subtitle {
            @extend .caps; margin-bottom: 10px; font-size: 25px; color: $dark-gray;
            line-height: 1.1;
        }
        .item-desc { margin-bottom: 0; font-size: 13px; color: $dark-gray; }
        & + .item-press {
            .item-details { border-top: 2px solid $dark-gray; }
        }
    }
    &.item-exhibition {
        margin-bottom: 60px;
        .item-img {
            @include img(1.52);
        }
        .item-details {
        }
        .item-title {
            @extend .caps; margin-bottom: 0px; font-size: 35px; line-height: 1.3;
        }
        .item-subtitle {
            @extend .caps; margin-bottom: 10px; font-size: 25px; color: $dark-gray;
            line-height: 1.1;
        }
        .item-desc { margin-bottom: 0; font-size: 13px; color: $dark-gray; }
    }
}

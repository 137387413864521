

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
}
}

@mixin placeholder {
    &::-webkit-input-placeholder { @content; }
    &:-moz-placeholder { /* Firefox 18- */ @content; }
    &::-moz-placeholder {  /* Firefox 19+ */ @content; }
    &:-ms-input-placeholder { @content; }
}

@mixin clearfix(){
    &:after {
	content: " "; visibility: hidden; display: block; height: 0; clear: both;
    }
}

@mixin proportional($contentselector: ".proportional-content", $hor: 1, $ver: 1){
    @include ratio($hor, $ver, $contentselector);
}

@mixin ratio($x: 1, $y: 1, $contentselector: ".nothing"){
    position: relative;
    &:before {
	content: ""; display: block;
	padding-top: percentage($y/$x);
	pointer-events: none;
    }
    #{unquote($contentselector)} {
	position: absolute; top: 0; left: 0; bottom: 0; right: 0;
    }
}

@mixin img($xRatio, $yRatio: 1){
    @include ratio($xRatio, $yRatio, ".nothing");
    background-size: cover; background-position: center center;
    background-repeat: no-repeat;
}

@mixin img-contain($xRatio, $yRatio: 1){
    @include img($xRatio, $yRatio); background-size: contain;
    background-repeat: no-repeat;
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin rel($left, $top: $left){
    position: relative; left: $left; top: $top;
}

@mixin abs($left, $top: $left){
    position: absolute; left: $left; top: $top;
}

@mixin absr($right, $top: $right){
    position: absolute; right: $right; top: $top;
}

@mixin absbl($bottom, $left: $bottom){
    position: absolute; bottom: $bottom; left: $left;
}

@mixin absbr($bottom, $right: $bottom){
    position: absolute; bottom: $bottom; right: $right;
}

@mixin flex-center(){
    display: flex; flex-direction: row; justify-content: center; align-items: center;
}

@mixin inline-flex-center(){
    display: inline-flex; flex-direction: row;
    justify-content: center; align-items: center;
}

@mixin flex-apart($align: center){
    display: flex; flex-direction: row; justify-content: space-between; align-items: $align;
}

@mixin arrowed($color, $width){
    position: relative;	background: $color;
    &:after {
	border: solid transparent; content: " ";
	height: 0; width: 0; position: absolute; pointer-events: none;
	border-color: rgba($color, 0); border-width: $width;
    }
}
@mixin arrowed-top($color, $width){
    @include arrowed($color, $width);
    &:after {
        margin-left: #{-1*$width}; bottom: 100%; left: 50%; border-bottom-color: $color;
        top: auto; right: auto;
    }
}
@mixin arrowed-right($color, $width){
    @include arrowed($color, $width);
    &:after {
        margin-top: #{-1*$width}; left: 100%; top: 50%; border-left-color: $color;
        right: auto; bottom: auto;
    }
}
@mixin arrowed-bottom($color, $width){
    @include arrowed($color, $width);
    &:after {
        margin-left: #{-1*$width}; top: 100%; left: 50%; border-top-color: $color;
        bottom: auto; right: auto;
    }
}
@mixin arrowed-left($color, $width){
    @include arrowed($color, $width);
    &:after {
        margin-top: #{-1*$width}; right: 100%; top: 50%; border-right-color: $color;
        left: auto; border: auto;
    }
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

/* changes appearence of similar (e.g. sibling or same class) elements (similar to the hovered element, except the hovered one) inside the element this mixin is applied to */
/* $child is e.g. ".item" and $dotless is "item" */
@mixin child-hover($child){
    /* $dotless: str-replace($child, '.', ''); */
    visibility: hidden;
    >#{$child} { visibility: visible; }
    >#{$child} { transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms; }
    &:hover >#{$child} { opacity: 0.4; /* transform: scale(0.9); */ }
    >#{$child}:hover {
        opacity: 1; /* transform: scale(1); */ transition-delay: 0ms, 0ms;
    }
}

/* works even on elements deep below the tree, not just direct descendants */
@mixin offspring-hover($child){
    /* $dotless: str-replace($child, '.', ''); */
    visibility: hidden;
    /* reset visibility on direct children, as desired .items can be deep below */
    >* { visibility: visible; }
    >*:not(#{$child}){ pointer-events: none; }
    /* #{$child} { visibility: visible; } */
    #{$child} {
        transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms;
        pointer-events: all;
    }
    &:hover #{$child} { opacity: 0.4; /* transform: scale(0.9); */ }
    #{$child}:hover {
        opacity: 1; /* transform: scale(1); */ transition-delay: 0ms, 0ms;
    }
}

@mixin flip-h() {
    -moz-transform: scaleX(-1); -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1); transform: scaleX(-1);
    filter: FlipH; -ms-filter: "FlipH";
}

/* left and right graidient bg curtains of $color,
with max intensity $level of bg color at the edges */
@mixin gradient-curtains($color : #000000, $width : 20%, $level : 1.0, $z : 1){
    position: relative;
    &:before {
	content: ''; display: block; position: absolute; left: 0; top: 0;
	width: $width; height: 100%; z-index: $z;
	@include gradient-horizontal(rgba($color,$level), rgba($color,0.0), 0%, 100%);
	pointer-events: none;
    }
    &:after {
	content: ''; display: block; position: absolute; right: 0; top: 0;
	width: $width; height: 100%; z-index: $z;
	@include gradient-horizontal(rgba($color,0.0), rgba($color,$level), 0%, 100%);
	pointer-events: none;
    }
}

@mixin sprites-custom-prefix($sprites) {
    @each $sprite in $sprites {
        $sprite-name: nth($sprite, 10);
        .i-#{$sprite-name} {
            @include sprite($sprite);
        }
    }
}
@mixin retina-sprites-custom-prefix($retina-groups) {
    @each $retina-group in $retina-groups {
        $sprite-name: nth($retina-group, 1);
        .i-#{$sprite-name} {
            @include retina-sprite($retina-group);
        }
    }
}

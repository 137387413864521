// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-right-name: 'arrow-right';
$arrow-right-x: 127px;
$arrow-right-y: 100px;
$arrow-right-offset-x: -127px;
$arrow-right-offset-y: -100px;
$arrow-right-width: 22px;
$arrow-right-height: 26px;
$arrow-right-total-width: 164px;
$arrow-right-total-height: 164px;
$arrow-right-image: '../i/sprite.png';
$arrow-right: (127px, 100px, -127px, -100px, 22px, 26px, 164px, 164px, '../i/sprite.png', 'arrow-right', );
$fb-name: 'fb';
$fb-x: 127px;
$fb-y: 42px;
$fb-offset-x: -127px;
$fb-offset-y: -42px;
$fb-width: 29px;
$fb-height: 29px;
$fb-total-width: 164px;
$fb-total-height: 164px;
$fb-image: '../i/sprite.png';
$fb: (127px, 42px, -127px, -42px, 29px, 29px, 164px, 164px, '../i/sprite.png', 'fb', );
$instagram-name: 'instagram';
$instagram-x: 127px;
$instagram-y: 71px;
$instagram-offset-x: -127px;
$instagram-offset-y: -71px;
$instagram-width: 29px;
$instagram-height: 29px;
$instagram-total-width: 164px;
$instagram-total-height: 164px;
$instagram-image: '../i/sprite.png';
$instagram: (127px, 71px, -127px, -71px, 29px, 29px, 164px, 164px, '../i/sprite.png', 'instagram', );
$logo-name: 'logo';
$logo-x: 0px;
$logo-y: 0px;
$logo-offset-x: 0px;
$logo-offset-y: 0px;
$logo-width: 127px;
$logo-height: 164px;
$logo-total-width: 164px;
$logo-total-height: 164px;
$logo-image: '../i/sprite.png';
$logo: (0px, 0px, 0px, 0px, 127px, 164px, 164px, 164px, '../i/sprite.png', 'logo', );
$play-name: 'play';
$play-x: 127px;
$play-y: 0px;
$play-offset-x: -127px;
$play-offset-y: 0px;
$play-width: 37px;
$play-height: 42px;
$play-total-width: 164px;
$play-total-height: 164px;
$play-image: '../i/sprite.png';
$play: (127px, 0px, -127px, 0px, 37px, 42px, 164px, 164px, '../i/sprite.png', 'play', );
$x-close-fancybox-name: 'x-close-fancybox';
$x-close-fancybox-x: 127px;
$x-close-fancybox-y: 126px;
$x-close-fancybox-offset-x: -127px;
$x-close-fancybox-offset-y: -126px;
$x-close-fancybox-width: 16px;
$x-close-fancybox-height: 16px;
$x-close-fancybox-total-width: 164px;
$x-close-fancybox-total-height: 164px;
$x-close-fancybox-image: '../i/sprite.png';
$x-close-fancybox: (127px, 126px, -127px, -126px, 16px, 16px, 164px, 164px, '../i/sprite.png', 'x-close-fancybox', );
$arrow-right-2x-name: 'arrow-right@2x';
$arrow-right-2x-x: 254px;
$arrow-right-2x-y: 200px;
$arrow-right-2x-offset-x: -254px;
$arrow-right-2x-offset-y: -200px;
$arrow-right-2x-width: 44px;
$arrow-right-2x-height: 52px;
$arrow-right-2x-total-width: 328px;
$arrow-right-2x-total-height: 328px;
$arrow-right-2x-image: '../i/sprite@2x.png';
$arrow-right-2x: (254px, 200px, -254px, -200px, 44px, 52px, 328px, 328px, '../i/sprite@2x.png', 'arrow-right@2x', );
$fb-2x-name: 'fb@2x';
$fb-2x-x: 254px;
$fb-2x-y: 84px;
$fb-2x-offset-x: -254px;
$fb-2x-offset-y: -84px;
$fb-2x-width: 58px;
$fb-2x-height: 58px;
$fb-2x-total-width: 328px;
$fb-2x-total-height: 328px;
$fb-2x-image: '../i/sprite@2x.png';
$fb-2x: (254px, 84px, -254px, -84px, 58px, 58px, 328px, 328px, '../i/sprite@2x.png', 'fb@2x', );
$instagram-2x-name: 'instagram@2x';
$instagram-2x-x: 254px;
$instagram-2x-y: 142px;
$instagram-2x-offset-x: -254px;
$instagram-2x-offset-y: -142px;
$instagram-2x-width: 58px;
$instagram-2x-height: 58px;
$instagram-2x-total-width: 328px;
$instagram-2x-total-height: 328px;
$instagram-2x-image: '../i/sprite@2x.png';
$instagram-2x: (254px, 142px, -254px, -142px, 58px, 58px, 328px, 328px, '../i/sprite@2x.png', 'instagram@2x', );
$logo-2x-name: 'logo@2x';
$logo-2x-x: 0px;
$logo-2x-y: 0px;
$logo-2x-offset-x: 0px;
$logo-2x-offset-y: 0px;
$logo-2x-width: 254px;
$logo-2x-height: 328px;
$logo-2x-total-width: 328px;
$logo-2x-total-height: 328px;
$logo-2x-image: '../i/sprite@2x.png';
$logo-2x: (0px, 0px, 0px, 0px, 254px, 328px, 328px, 328px, '../i/sprite@2x.png', 'logo@2x', );
$play-2x-name: 'play@2x';
$play-2x-x: 254px;
$play-2x-y: 0px;
$play-2x-offset-x: -254px;
$play-2x-offset-y: 0px;
$play-2x-width: 74px;
$play-2x-height: 84px;
$play-2x-total-width: 328px;
$play-2x-total-height: 328px;
$play-2x-image: '../i/sprite@2x.png';
$play-2x: (254px, 0px, -254px, 0px, 74px, 84px, 328px, 328px, '../i/sprite@2x.png', 'play@2x', );
$x-close-fancybox-2x-name: 'x-close-fancybox@2x';
$x-close-fancybox-2x-x: 254px;
$x-close-fancybox-2x-y: 252px;
$x-close-fancybox-2x-offset-x: -254px;
$x-close-fancybox-2x-offset-y: -252px;
$x-close-fancybox-2x-width: 32px;
$x-close-fancybox-2x-height: 32px;
$x-close-fancybox-2x-total-width: 328px;
$x-close-fancybox-2x-total-height: 328px;
$x-close-fancybox-2x-image: '../i/sprite@2x.png';
$x-close-fancybox-2x: (254px, 252px, -254px, -252px, 32px, 32px, 328px, 328px, '../i/sprite@2x.png', 'x-close-fancybox@2x', );
$spritesheet-width: 164px;
$spritesheet-height: 164px;
$spritesheet-image: '../i/sprite.png';
$spritesheet-sprites: ($arrow-right, $fb, $instagram, $logo, $play, $x-close-fancybox, );
$spritesheet: (164px, 164px, '../i/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 328px;
$retina-spritesheet-height: 328px;
$retina-spritesheet-image: '../i/sprite@2x.png';
$retina-spritesheet-sprites: ($arrow-right-2x, $fb-2x, $instagram-2x, $logo-2x, $play-2x, $x-close-fancybox-2x, );
$retina-spritesheet: (328px, 328px, '../i/sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$arrow-right-group-name: 'arrow-right';
$arrow-right-group: ('arrow-right', $arrow-right, $arrow-right-2x, );
$fb-group-name: 'fb';
$fb-group: ('fb', $fb, $fb-2x, );
$instagram-group-name: 'instagram';
$instagram-group: ('instagram', $instagram, $instagram-2x, );
$logo-group-name: 'logo';
$logo-group: ('logo', $logo, $logo-2x, );
$play-group-name: 'play';
$play-group: ('play', $play, $play-2x, );
$x-close-fancybox-group-name: 'x-close-fancybox';
$x-close-fancybox-group: ('x-close-fancybox', $x-close-fancybox, $x-close-fancybox-2x, );
$retina-groups: ($arrow-right-group, $fb-group, $instagram-group, $logo-group, $play-group, $x-close-fancybox-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}


@mixin item-bottom-line-xs(){
    border-bottom: 1px solid $dark-gray; padding-bottom: 20px; margin-bottom: 22px;
    &:last-child { margin-bottom: 0px; }
}

@media (min-width: 1200px){
}
@media (min-width: 1366px){
}
@media (min-width: 1440px){
}
@media (min-width: 1680px){
}
@media (min-width: 1920px){
}
@media (min-width: 2560px){
}
/* all tablets */
@media (min-width: 768px) and (max-width: 1199px){
    .navbar-nav { padding-left: 11px; }
}
/* tablet landscape */
@media (min-width: 992px) and (max-width: 1199px){
}
/* tablet portrait */
@media (min-width: 768px) and (max-width: 991px){
    .container {
        width: 730px;
    }
    .header__left { left: 0 !important; }
    .sidebar-col { right: 0 !important; }
}
/* all mobiles */
@media (min-width: 320px) and (max-width: 767px){
    .container {
        padding: 0px 30px;
    }
    .footer {
        margin-top: 50px; padding-bottom: 60px;
    }
    .block {
        & + .block { margin-top: 25px; }
    }
    .home-feats-block { flex-direction: column; }
    .home-text-block { }
    .home-partners-block {
        >* {
            width: 100px;
            & + * { margin-left: 20px; }
        }
    }
    /*@mixin item-bottom-line-xs(){
        border-bottom: 1px solid $dark-gray; padding-bottom: 20px; margin-bottom: 22px;
        &:last-child { margin-bottom: 0px; }
    }*/
    .item.item-home-event {
        @include item-bottom-line-xs();
        .item-details {
            margin-top: 20px;
        }
        .item-title { margin-bottom: 0px; line-height: 1.1; }
        .item-subtitle { margin-bottom: 10px; }
        .item-desc { margin-bottom: 10px; }
        & + .item-home-event {
            margin-left: 0px;
        }
    }
    .item.item-program {
        @include item-bottom-line-xs();
        flex-direction: column;
        .item-img { order: 1; }
        .item-details {
            order: 2; padding-right: 0px; padding-top: 10px; text-align: left;
        }
    }
    .contact-text-right { margin-top: 20px; }
    .contact-heading { margin-top: 20px; margin-bottom: 20px; }
    .contact-form {
        padding-top: 30px;
        form { width: 90%; }
    }
    .exhibitions-content {
        padding: 0px;
        .row {
            > div { max-width: 100%; }
        }
        .item.item-exhibition {
            @include item-bottom-line-xs();
            /* on this page, items are in separate columns so each one is last child */
            &:last-child { margin-bottom: 22px; }
        }
    }
    .item.item-press {
        width: 90%;
    }
    .item.item-home-event .item-title { padding-right: 0; }
}
/* probably mobile landscape but can be bigger mobile portrait */
@media (min-width: 480px) and (max-width: 767px){
}
/* mobile portrait */
@media (min-width: 320px) and (max-width: 479px){
}
/* starting orientation-specific styles */
@media (min-width: 992px) and (max-width: 1199px) and (orientation: portrait){
}
@media (min-width: 768px) and (max-width: 991px) and (orientation: portrait){
}
@media (min-width: 320px) and (max-width: 767px) and (orientation: portrait){
}
@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
}



@media screen and (max-width: 768px) {
    /* .visible-xs-inline { display: inline-block !important; } */
}
@media screen and (min-width: 768px) {
    /* .visible-xs-inline { display: none !important; } */
}

@media screen and (min-width: $grid-float-breakpoint){
    .navbar-nav {
        >li {
            
        }
    }
}
@media screen and (max-width: $grid-float-breakpoint-max){
    $hamburger-wh: 60px;
    /* $offcanvas-w: calc(100vw - #{$hamburger-wh}); */
    $offcanvas-w: 100vw;
    .header {
        padding-top: 15px; margin-bottom: 12px;
        .container { position: relative; }
        &__left {
            @include absbl(10px, 15px); z-index: 1;
        }
        &__logo {
            transform: scale(0.5);
            transform-origin: left bottom;
        }
        &__right {
            width: 100%; height: 90px; padding-left: 85px; padding-bottom: 10px;
        }
        &__social {
            position: relative; z-index: 2;
            transform: scale(0.7); transform-origin: left bottom;
            &-link + .header__social-link { margin-left: 10px; }
        }
        &__lang-link {
            margin-bottom: 31px; font-size: 20px;
        }
    }
    .site-content .container {
        position: relative;
    }
    .sidebar-col {
        padding: 0px; @include absbr(calc(100% + 22px), 15px);
    }
    /*  */
    .navbar {
        position: static; min-height: auto;
        &-toggle {
            margin: 0px; padding: 0px; border: 0;
            /* please the Safari god - flex-center only works on direct children there */
            .iconbar-wrapper {
            }
            .icon-bar {
                width: 23px; height: 2px;
                &+.icon-bar { margin-top: 4px; }
                &:nth-child(1){ margin-left: 3px; }
                &:nth-child(2){ margin-left: 3px;  }
                &:nth-child(3){ margin-left: 3px; }
            }
            &.is-open {
                .icon-bar {
                    &:nth-child(1){ margin-left: 0px; }
                    &:nth-child(3){ margin-left: 1px; margin-top: 2px; }
                }
            }
        }
    }
    .offcanvas-toggle {
        &.is-open {
            .icon-bar {
                &:nth-child(1){
                    transform: rotate(45deg) translate(4px,3px);
                }
            }
        }
    }
    html.stop-scrolling {
        height: 100%; overflow: hidden;
        .global-wrapper, .site-content { overflow: hidden; }
        /* .navbar-toggle { z-index: 10000; } */
    }
    body.offcanvas-stop-scrolling {
        overflow: hidden;
        /* fix tab buttons overlaying over navbar */
        .nav-tabs>li { z-index: 0; }
        .navbar-toggle {
            position: fixed; right: 15px; top: 81px; z-index: 999;
        }
    }
    .navbar-offcanvas {
        max-width: $offcanvas-w;
        /* max-width: 100%; width: 100%; padding-right: #{$hamburger-wh} !important; */
        /* here Safari goes again - no nested calcs */
        /* left: calc(-1 * #{$offcanvas-w}); */
        left: -100vw; top: 104px;
        /* left: calc(-1 * 100vw); */
        &:before {
            /* prevent background scrolling */
            content: ''; display: block; @include abs(100%,#{$hamburger-wh}); width: #{$hamburger-wh}; z-index: 10000;
            height: calc(100vh - #{$hamburger-wh}); pointer-events: all; background-color: transparent;
        }
        background-color: $white; padding: 0;
        &.in {
            overflow: visible;  /*for shadow*/
            left: 0; height: calc(100vh - 104px);
            &.offcanvas-transform { transform: translateX($offcanvas-w); }
            &:after {
                content: ''; display: block; @include abs(100%, $hamburger-wh);
                @include size($hamburger-wh, calc(100% - #{$hamburger-wh}));
                pointer-events: none; background-color: rgba($black, 0.4); z-index: 1;
            }
            .navbar-offcanvas-content {
                overflow: hidden; /* compensate for deactivated ov: hidden on parent*/
            }
            .navbar-nav {
                display: block; padding: 30px 15px 15px 22px; margin: 0px;
                overflow: auto; position: relative;
                >li {
                    display: block;
                    & + li { margin-top: 22px; }
                    >a {
                        .navbar-subitems-expand {
                        }
                        &:hover {
                        }
                    }
                    >ul {
                        margin-top: 15px;
                        >li {
                            & + li { margin-top: 10px; }
                            >a {
                                /* ..the extend is defined at root below */
                                &:before {
                                    content: '';
                                    display: inline-block; margin-right: 10px;
                                }
                            }
                        }
                    }
                    &.active {
                        >a { color: rgba($dark-gray, 0.6); }
                    }
                    &.expanded {
                        >a {
                            .navbar-subitems-expand { transform: rotate(180deg); }
                        }
                        >ul { display: block;/* hidden in _essentials.scss */ }
                    }
                }
            }
        }
    }
}
.navbar-offcanvas.in .navbar-nav>li>ul>li>a:before {
    /* @extend .i-arrow-turn-gray; */
}


.home-feats-block {
    display: flex; flex-direction: row;
    .item-home-event {
        flex: 1;
        & + .item-home-event { margin-left: 30px; }
    }
}

.home-text-block {
}

.home-partners-block {
    >* {
        width: 100px;
        & + * { margin-left: 40px; }
    }
}
.home-map-block {
}


.home-map-wrapper {
    @include proportional(".home-map", 1.45, 1);
}
.home-map {
    @include size(100%);
}